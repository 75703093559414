import React from "react";
import { useFormattedMessage } from "hooks";
import SideModal from "components/SideModal";
import NavLink from "components/NavLink";

const messages = {
    productSubscribedTitle: "product_subscribed_title",
    joinNow: "join_now",
    title: "register_for_more_product_information",
};
const WrappedProductSubscription = ({ modalOpen, closeModal }) => {
    const { formatMessage } = useFormattedMessage();

    return (
        <SideModal open={modalOpen} onCloseButtonClick={closeModal} heading={formatMessage(messages.productSubscribedTitle)}>
            <div className="content-part">
                <h4 className="heading mb-10">{formatMessage(messages.title)}</h4>
                <NavLink className="w-100" href={"/register"}>
                    <button className="action-btn mb-0 target-button">{formatMessage(messages.joinNow)}</button>
                </NavLink>
            </div>
        </SideModal>
    );
};

export default WrappedProductSubscription;
